/* Reset CSS */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Style to make the Terms button look like a link */
.terms-link {
    background: none;
    border: none;
    color: #6f3ff5;
    cursor: pointer;
    text-decoration: underline;
    font-size: 0.9rem;
    padding: 0;
}

    .terms-link:hover {
        text-decoration: none;
    }

/* Basic layout structure */
body, html {
    width: 100%;
    overflow-x: hidden; /* Prevent horizontal scrolling */
    color: #333;
    font-family: 'Montserrat', sans-serif;
}

.landing-page {
    width: 100%;
}

/* Container */
.container {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 2px;
    width: 100%;
}

/* Navbar Styling */
.navbar {
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px; /* Increase padding for better alignment */
    flex-wrap: nowrap; /* Prevent wrapping */
}

.logo {
    max-width: 150px; /* Use a max-width to ensure it scales */
    height: auto;
    margin-right: auto; /* Aligns logo to the far left */
    padding-left: 10px; /* Adds a bit of spacing from the edge */
}

.navbar-left {
    display: flex;
    align-items: center;
    flex: 1; /* Ensure the left side can take as much space as needed */
}

.navbar-right {
    display: flex;
    align-items: center;
    gap: 15px; /* Adjust this to control spacing between nav items */
    margin-right: 20px; /* Add a reasonable amount of margin */
    justify-content: flex-start; /* Align items to the left to prevent spacing */
}

.nav-button {
    padding: 10px 20px;
    font-size: 1rem;
    border: 2px solid transparent;
    background-color: transparent;
    color: #000;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;
    margin: 0; /* Make sure there are no default margins */
}

    .nav-button:hover {
        background-color: transparent;
        color: #249cbd;
    }

/* "Log In" Button Style */
.nav-button.log-in {
    color: #000;
}

.nav-button.log-in:hover {
    background-color: transparent;
    color: #249cbd;
}

/* "Get Started" Button Style */
.get-started {
    background-color: #ffffff;
    color: #345170;
    border: 1px solid #345170;
    cursor: pointer;
    transition: background-color 0.3s;
}

    .get-started:hover {
        background-color: #345170;
        color: white;
    }

.subscribe-button {
    background-color: #345170;
    color: white;
    border: 2px solid #345170;
}

    .subscribe-button:hover {
        background-color: transparent;
        color: #345170;
        border: 2px solid #345170;
    }

/* Hero Section */
.hero {
    position: relative;
    text-align: center;
    padding: 60px 0;
    background-image: url('./Hero_Background.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #f1f1f1;
}

    .hero::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4); /* Adjust opacity as needed */
        z-index: 1;
    }

.hero-title,
.hero-subtitle {
    position: relative;
    z-index: 2;
}

.hero-title {
    font-size: 2rem;
    color: #ffffff;
    margin-bottom: 20px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); /* Dark shadow for readability */
}

.hero-subtitle {
    font-size: 1rem;
    margin-bottom: 40px;
    color: #ffffff;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7); /* Adjust shadow size as needed */
}

/* BarryAboutSection CSS */

.barry-about-section {
    padding: 20px;
    max-width: 100%;
    min-width: 100%;
    margin-top: 50px;
    background-color: #f9f9f9;
    display: flex;
    gap: 30px;
    align-items: flex-start;
}

.barry-about-content {
    max-width: 50%;
    min-width: 50%;
    flex: 2;
}

.barry-about-header {
    text-align: start;
    max-width: 90%;
    min-width: 90%;
    margin: 0 auto;
}

.barry-about-image {
    flex: 1;
    text-align: center;
    align-self: center;
    margin-right: 30px;
}

.barry-about-image img {
    max-width: auto;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}


/* Features Section */
.features {
    padding: 40px 0;
    background-color: #ffffff;
}

    .features .container {
        padding: 0;
    }

    .features h2 {
        font-size: 1.8rem;
        text-align: center;
        margin-bottom: 40px;
    }

.feature-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.feature-item {
    max-width: 100%;
    text-align: center;
}

/* Pricing Section */
.pricing-section {
    padding: 60px 0; /* Increase vertical padding */
    text-align: center;
    background-color: #ffffff;
}

.pricing-section .container {
    max-width: 80%;
    margin: 0 auto;
}

.pricing-section h2 {
    font-size: 2.8rem; /* Make the heading more prominent */
    margin-bottom: 30px;
    color: #345170;
}

.pricing-section li {
    font-size: 1.5rem; /* Larger description text */
    margin-bottom: 20px;
    color: #666;
    line-height: 1.6;
}

.pricing-section p {
    font-size: 1.5rem; /* Larger description text */
    margin-bottom: 20px;
    color: #666;
    line-height: 1.6;
}

.plan-options {
    display: flex;
    gap: 40px;
    justify-content: center;
    align-items: stretch; /* Ensures all children stretch to the same height */
    flex-wrap: wrap; /* Allows wrapping on smaller screens */
    margin-top: 40px;
}

.plan-option {
    position: relative; /* Ensure the box is the reference point for the ribbon */
    background-color: #ffffff;
    padding: 40px;
    border: 2px solid #ddd;
    border-radius: 10px;
    text-align: center;
    flex: 1; /* Ensures all boxes have the same width */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Distributes inner elements evenly */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease, transform 0.3s ease;
}

.ribbon {
    position: absolute; /* Position relative to the parent box */
    top: 0;
    left: 0;
    background: #ff4d4f; /* Red background for the ribbon */
    color: white; /* White text for contrast */
    font-size: 0.9rem; /* Adjust font size */
    font-weight: bold;
    padding: 5px 15px; /* Adjust padding for the ribbon */
    clip-path: polygon(0 0, 100% 0, 85% 100%, 0 100%); /* Creates the ribbon shape */
    z-index: 2; /* Make sure it stays above other content */
    text-align: center;
    transform-origin: top left;
    border-top-left-radius: 10px; /* Matches the border radius of the pricing box */
}

.ribbon::before {
    content: ''; /* Add a little triangle for the ribbon */
    position: absolute;
    top: 100%;
    left: 0;
    border-style: solid;
    border-width: 0 15px 15px 0; /* Creates the triangle */
    border-color: transparent transparent #ff4d4f transparent; /* Matches ribbon color */
}

.plan-option:hover {
    border-color: #345170;
    transform: translateY(-5px);
}


.plan-option h3 {
    font-size: 2rem; /* Uniform title size */
    color: #345170;
    margin: 0 0 10px; /* Consistent bottom margin */
    line-height: 1.2; /* Adjust spacing for alignment */
}

.plan-option p.placeholder {
    visibility: hidden; /* Makes the placeholder invisible but retains its space */
    font-size: 1.5rem;
}

.plan-option p {
    font-size: 1.4rem;
    margin: 10px 0;
    line-height: 1.5;
}

.plan-option ul {
    padding-left: 20px; /* Remove default padding for the list */
    margin: 0; /* Ensure no extra margin for the list */
    list-style-position: outside; /* Position the bullet outside */
}

.plan-option li {
    font-size: 1.1rem;
    margin: 10px 0;
    line-height: 1.5;
    text-align: left; /* Aligns text to the left */
    padding-left: 1.5rem; /* Indent both the bullet and text */
    list-style-type: disc; /* Standard bullet type */
    list-style-position: outside; /* Keeps the bullet outside */
}

.discount {
    color: #ff4d4f !important;
    font-weight: bold;
    font-size: 1.5rem; /* Keep the size for prominence */
    margin-top: 10px;
    min-height: 1.5rem; /* Ensure this element occupies consistent space */
    line-height: 1.5rem; /* Aligns vertically with placeholder space */
}


.pricing-section .pricing-footer p {
    font-size: 1.0rem !important; /* Force updated size */
    font-style: italic;
    color: #666;
    margin-top: 30px;
}
/* Wrapper specifically for centering the button */
.button-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
}

.plan-button {
    background-color: #ffffff;
    color: #345170;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #345170; /* Sets border width, style, and color */
    border-radius: 5px;
    cursor: pointer;
    margin-top: 30px; /* Automatically push the button to the bottom */
    width: 50%;
    transition: background-color 0.3s;
}

.plan-button:hover {
    background-color: #345170;
    color: white;
}

/* Footer */
.landing-footer {
    background-color: #1a1a1a;
    padding: 30px 0;
    text-align: center;
}

    .landing-footer p {
        margin: 0;
        font-size: 1rem;
        color: #ffffff;
    }

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    max-width: 500px;
    width: 100%;
    text-align: center;
}

    .modal-content h2 {
        font-size: 1.5rem;
        margin-bottom: 15px;
    }

    .modal-content p {
        font-size: 1rem;
        margin-bottom: 20px;
    }

/* Membership Page Container */
.membership-page {
    padding: 20px;
    text-align: center;
}

/* Cancel Membership Button */
.cancel-membership-button {
    background-color: #ff4d4f;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 20px;
}

    .cancel-membership-button:hover {
        background-color: #d9363e;
    }


/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
    .navbar .container {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;
    }

    .logo {
        width: 100px; /* Smaller logo for mobile */
        padding-left: 5px; /* Closer to the left edge */
    }

    .hero {
        padding: 60px 0;
    }

    .hero-title {
        font-size: 1.5rem;
    }

    .hero-subtitle {
        font-size: 0.8rem;
    }

    .navbar-right {
        flex-direction: row;
        gap: 5px;
    }

    .nav-button {
        padding: 8px 12px; /* Reduced padding for mobile */
        font-size: 0.9rem;
    }

    .barry-about-section {
    flex-direction: column;
    align-items: center;
}

    .barry-about-content, .barry-about-image {
        max-width: 100%;
        min-width: 100%;
    }

    .barry-about-image {
        display: none;
    }
}

/* Media Queries for Tablets and Desktops */
@media (min-width: 768px) {
    .navbar .container {
        padding: 20px 40px;
    }

    .navbar-right {
        gap: 20px;
    }

    .container {
        padding: 0 40px;
    }

    .hero {
        padding: 80px 0;
    }

    .hero-title {
        font-size: 1.5rem;
    }

    .hero-subtitle {
        font-size: 0.8rem;
    }

    .features {
        padding: 80px 0;
    }

        .features h2 {
            font-size: 2.5rem;
            margin-bottom: 60px;
        }

    .feature-list {
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    }

    .feature-item {
        max-width: 45%;
    }
}

@media (min-width: 992px) {
    .navbar .container {
        padding: 20px 60px;
    }

    .container {
        padding: 0 60px;
    }

    .hero {
        padding: 100px 0;
    }

    .hero-title {
        font-size: 4rem;
    }

    .hero-subtitle {
        font-size: 1.5rem;
    }

    .features {
        padding: 100px 0;
    }

        .features h2 {
            font-size: 3rem;
        }

    .feature-item {
        max-width: 30%;
    }
}

/* Ensure Images are Responsive */
img {
    max-width: 100%;
    height: auto;
    display: block;
}
