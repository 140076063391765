/* Membership Page Container */
.membership-page {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    background-color: white; /* Ensure full white background */
}

/* Section Styling (Aligns with Reference Layout) */
.subscription-details {
    padding: 20px 0;
    border-bottom: 1px solid #e0e0e0;
}

    .subscription-details h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 15px;
        color: #333;
        text-align: left;
    }

    .subscription-details p {
        font-size: 14px; /* Match the email address text size from example */
        color: #555;
        margin: 5px 0;
        text-align: left;
    }

/* Manage Membership Section */
.manage-membership {
    padding: 5px 0;
    border-bottom: 1px solid #e0e0e0;
}

    .manage-membership h2 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 15px;
        color: #333;
        font-weight: bold;
        text-align: left;
    }

.cancel-membership-button {
    background-color: #ff4d4f;
    color: white;
    padding: 10px 20px;
    font-size: 14px; /* Slightly smaller button text for a cleaner look */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

    .cancel-membership-button:hover {
        background-color: #d9363e;
    }

    /* Disabled State Styling for Cancel Membership Button */
    .cancel-membership-button:disabled {
        background-color: #d3d3d3; /* Grey background for disabled state */
        color: #a0a0a0; /* Lighter text color for readability */
        cursor: not-allowed; /* Change cursor to indicate it's not clickable */
    }

/* Response Message Styling */
.response-message {
    font-size: 14px;
    margin-top: 15px;
    color: #28a745; /* Green color for success */
}

    .response-message.error {
        color: #dc3545; /* Red color for errors */
    }

/* Remove Borders for Full White Background Consistency */
.membership-page > div:last-child {
    border-bottom: none;
}
