/* General reset for body to remove default margin and padding */
body {
    margin: 0;
    padding: 0;
    background-color: #f7f7f8;
    font-family: 'Montserrat', sans-serif;
}

/* Main container to divide the screen into a left panel and chat area */
.main-container {
    display: flex;
    height: 100vh;
}


.logo {
    width: 110px;
    height: auto;
    margin-bottom: 10px;
    padding-left: 5px; /* Adjust padding for desktop */
}

/* Typing animation for welcome message */
@keyframes typing {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

@keyframes blink-caret {
    0%, 100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    90%, 100% {
        opacity: 0;
    }
    /* Ensure the caret disappears at the end */
}

.welcome-message {
    display: flex;
    justify-content: center;
    margin: 10px auto;
    padding-bottom: 60px;
}

    .welcome-message .text {
        font-size: 1.8em;
        color: #333;
        white-space: nowrap;
        overflow: hidden;
        animation: typing 2s steps(30, end) forwards;
    }

    .welcome-message .caret {
        font-size: 1.8em;
        color: #333;
        animation: blink-caret 0.7s step-end 5 forwards;
        opacity: 0;
    }

.notification-banner {
    background-color: #ff4d4f; 
    color: #ffffff; /* Dark text for contrast */
    text-align: center;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid #ffeeba;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    width: 100%; /* Ensure it spans across the container */
    box-sizing: border-box;
}

/* Left panel reserved for future use */
.left-panel {
    width: 240px;
    min-width: 240px; /* Prevent the left panel from shrinking */
    flex-shrink: 0; /* Disable shrinking */
    background-color: #1E1E1E;
    border-right: 1px solid #eaeaea;
    height: 100vh;
    padding: 80px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
}

/* Desktop UserButton */
.desktop-user-button {
    position: absolute;
    top: 20px;
    left: 20px;
}

/* Mobile UserButton */
.mobile-user-button {
    display: none;
}

/* Chat container - fills remaining space next to the left panel */
.chat-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    padding-right: 30px;
    padding-left: 20px; /* Reduced padding on both PC and mobile */
    box-sizing: border-box;
    background-color: #ffffff;
    position: relative;
}

.chat-container.with-background {
    background-image: url('./images/barry_background2.png'); /* Adjust path if needed */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(255, 255, 255, 0.85); /* Optional semi-transparent overlay */
    background-blend-mode: overlay;
}


/* Chat box area for messages */
.chat-box {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
    margin-bottom: 10px;
    padding-right: 80px; /* Adjusted padding for more space on PC */
    padding-left: 80px;
}

/* Chat bubbles for both user and bot messages */
.message {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
}

    .message p.user {
        background-color: #4a4a4a;
        color: white;
        align-self: flex-end;
        padding: 10px 15px;
        border-radius: 10px 10px 0 10px;
        max-width: 75%;
        word-wrap: break-word;
        margin-left: auto;
        font-size: 15px;
        line-height: 1.5;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    }

    .message .bot {
        background-color: #e1e1e1;
        color: #333;
        padding: 10px 25px;
        border-radius: 10px;
        max-width: 75%;
        word-wrap: break-word;
        margin-right: auto;
        font-size: 15px;
        line-height: 1.5;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
        font-weight: normal;
    }

        /* HTML formatting in bot messages */
        .message .bot strong {
            font-weight: bold;
        }

        .message .bot em {
            font-style: italic;
        }

        .message .bot ul {
            margin-top: 10px;
            margin-bottom: 10px;
            padding-left: 20px;
        }

        .message .bot li {
            margin-bottom: 5px;
        }

        .message .bot p {
            margin: 10px 0;
        }

/* Input area container */
.input-container {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    background-color: #f7f7f8;
    box-shadow: none;
    border: none;
    margin-right: 120px;
    margin-left: 80px;
}

    .input-container textarea {
        flex-grow: 1;
        padding: 4px 8px;
        font-size: 15px;
        border: none;
        border-radius: 5px;
        outline: none;
        background-color: #f7f7f8;
        color: #000000;
        resize: none;
        overflow-y: hidden;
        height: auto;
        line-height: 1.1;
        min-height: 20px;
    }

    .input-container button {
        background-color: #345170;
        color: white;
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 15px;
        transition: background-color 0.3s;
    }

        .input-container button:hover {
            background-color: transparent;
            color: #345170;
            border: 1px solid #345170;
        }

        .input-container input:disabled,
        .input-container button:disabled {
            background-color: #ddd;
            cursor: not-allowed;
        }

/* Spinner styling */
.spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    color: #007bff;
    font-size: 14px;
}

.spinner-icon {
    margin-right: 8px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Small footer text below input box */
.footer-text {
    font-size: 12px;
    color: #666;
    margin-top: 5px;
    text-align: center;
}

/* Scrollbar styling for chat box */
.chat-box::-webkit-scrollbar {
    width: 8px;
}

.chat-box::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
}

.chat-box::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

/* Dim the chat box when disabled */
.chat-box.disabled {
    pointer-events: none;
    opacity: 0.5;
}

/* Disable input when terms not accepted */
.input-container textarea[disabled],
.input-container button[disabled] {
    background-color: #f0f0f0;
    cursor: not-allowed;
}

/* Sport Tiles */
.sport-tiles {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px 0;
    gap: 30px;
}

.tile-row {
    display: flex;
    justify-content: center;
    gap: 40px; /* Increased gap to make tiles look less cramped */
}

.tile {
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    cursor: pointer;
}

    .tile img {
        width: auto;
        height: auto;
        padding: 10px;
    }

    .tile.active {
        border: 2px solid #007bff;
    }

    .tile.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    .tile .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.6);
        color: white;
        font-size: 0.6rem;
    }

/* Sport Tiles Modal (when changing sport after conversation start) */
.sport-tiles-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.95);
    padding: 50px; /* Increased padding for better spacing */
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
    z-index: 1000;
}

.tile-row-modal {
    display: flex;
    justify-content: center;
    gap: 40px; /* Added gap to ensure there is space between tiles in the modal */
    margin-bottom: 20px; /* Added margin to create space between rows */
}

/* Change Sport Button */
.change-sport-button {
    margin-top: 5px;
    padding: 8px;
    background-color: #1E1E1E;
    color: #ffffff;
    border: solid;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

    .change-sport-button:hover {
        background-color: #2c3e50;
        color: #ffffff;
        border: none;
    }

.selected-sport-icon {
    height: 26px;
    width: auto;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
    .left-panel {
        display: none;
    }

    .main-container {
        flex-direction: column;
        height: 100vh;
    }

    .logo {
        padding-left: 5px;
        margin-left: 0;
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
    }

    .sport-tiles {
        margin-top: 40px; /* Add top margin to create vertical spacing for the tiles */
    }

    .welcome-message .text {
        font-size: 1.2em;
    }

    .notification-banner {
        font-size: 14px;
        padding: 10px;
    }

    .chat-container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding: 10px 15px;
        position: relative;
    }

    .mobile-user-button {
        display: block;
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .desktop-user-button {
        display: none;
    }

    .chat-box {
        flex-grow: 1;
        overflow-y: auto;
        padding: 10px 0;
        margin-bottom: 0;
        padding-right: 20px;
        padding-left: 20px;
    }

    /* Chat bubbles for both user and bot messages */
    .message {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
    }

        .message p.user {
            background-color: #4a4a4a;
            color: white;
            align-self: flex-end;
            padding: 10px 15px;
            border-radius: 10px 10px 0 10px;
            max-width: 75%;
            word-wrap: break-word;
            margin-left: auto;
            font-size: 15px;
            line-height: 1.5;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
        }

        .message .bot {
            background-color: #e1e1e1;
            color: #333;
            padding: 10px 25px;
            border-radius: 10px;
            max-width: 75%;
            word-wrap: break-word;
            margin-right: auto;
            font-size: 15px;
            line-height: 1.5;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
        }

            .message .bot strong {
                font-weight: bold;
            }

            .message .bot em {
                font-style: italic;
            }

            .message .bot ul {
                margin-top: 10px;
                margin-bottom: 10px;
                padding-left: 20px;
            }

            .message .bot li {
                margin-bottom: 5px;
            }

            .message .bot p {
                margin: 10px 0;
            }

    .input-container {
        flex-direction: column;
        align-items: stretch;
        margin: 0 10px 10px 10px;
        padding: 5px;
        background-color: #ffffff;
        border-top: 1px solid #eaeaea;
        padding-right: 10px;
        padding-left: 10px;
    }

        .input-container textarea {
            padding: 8px;
            font-size: 14px;
            border: none;
            border-radius: 5px;
            outline: none;
            background-color: #f7f7f8;
            color: #000000;
            resize: none;
            min-height: 50px;
            margin-bottom: 10px;
        }

        .input-container button {
            width: 100%;
            padding: 10px;
            font-size: 16px;
        }

    .footer-text {
        margin-bottom: 10px;
    }
}
