/* FAQModal.css */

.faq-modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
}

.faq-modal {
	background: #fff;
	padding: 20px 40px;
	border-radius: 10px;
	max-width: 700px;
	width: 100%;
	height: 95vh; /* Set initial modal height to 80% of the viewport height */
	max-height: 90vh; /* Allow the modal to expand up to 90% of the viewport height */
	overflow-y: auto; /* Add vertical scroll if content exceeds max height */
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
	text-align: center;
	position: relative;
	/* Apply border-radius to ensure rounded corners when scrolling */
	border-radius: 10px;
	scroll-behavior: smooth;
}

.close-button {
	position: absolute;
	top: 10px;
	right: 15px;
	background: none;
	border: none;
	font-size: 1.5rem;
	cursor: pointer;
	color: #666;
}

	.close-button:hover {
		color: #ff4d4f;
	}

.faq-modal h2 {
	color: #345170;
	margin-bottom: 40px;
}

.faq-content {
	text-align: left;
}

	.faq-content h3 {
		color: #345170;
		margin-top: 15px;
		font-size: 1.2rem;
		margin-bottom: 10px;
	}

	.faq-content p {
		font-size: 1rem;
		color: #666;
		margin-bottom: 15px;
		line-height: 1.5;
	}

/* Custom scrollbar styling for a more modern look */
.faq-modal::-webkit-scrollbar {
	width: 10px; /* Width of the scrollbar */
}

.faq-modal::-webkit-scrollbar-track {
	background: #f1f1f1; /* Background of the scrollbar track */
	border-radius: 10px; /* Rounded corners for the track */
}

.faq-modal::-webkit-scrollbar-thumb {
	background: #345170; /* Color of the scrollbar thumb */
	border-radius: 10px; /* Rounded corners for the scrollbar thumb */
	border: 2px solid #f1f1f1; /* Space between the thumb and edge of the track for a modern look */
}

	.faq-modal::-webkit-scrollbar-thumb:hover {
		background: #6f3ff5; /* Color of the scrollbar thumb when hovered */
	}
