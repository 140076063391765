/* Modal background overlay */
.subscription-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Modal content container */
.subscription-modal {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 30px;
    max-width: 600px;
    min-height: 400px;
    width: 90%;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
    position: relative;
    box-sizing: border-box;
    text-align: center;
}

    /* Close button styling */
    .subscription-modal .close-button {
        position: absolute;
        top: 20px;
        right: 20px;
        background: transparent;
        border: none;
        font-size: 24px;
        cursor: pointer;
        color: #333;
    }

    /* Modal heading */
    .subscription-modal h2 {
        font-size: 24px;
        color: #345170;
        margin-bottom: 10px;
        margin-top: 10px;
    }

/* Plan selection container */
.plan-options {
    display: flex;
    gap: 20px;
    margin: 50px 0;
    justify-content: space-between;
    flex-wrap: wrap;

}

.plan-option {
    position: relative; /* Ensure the box is the reference point for the ribbon */
    background-color: #ffffff;
    padding: 40px;
    border: 2px solid #ddd;
    border-radius: 10px;
    text-align: center;
    flex: 1; /* Ensures all boxes have the same width */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Distributes inner elements evenly */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease, transform 0.3s ease;
}

.ribbon {
    position: absolute; /* Position relative to the parent box */
    top: 0;
    left: 0;
    background: #ff4d4f; /* Red background for the ribbon */
    color: white; /* White text for contrast */
    font-size: 0.9rem; /* Adjust font size */
    font-weight: bold;
    padding: 5px 15px; /* Adjust padding for the ribbon */
    clip-path: polygon(0 0, 100% 0, 85% 100%, 0 100%); /* Creates the ribbon shape */
    z-index: 2; /* Make sure it stays above other content */
    text-align: center;
}

    .ribbon::before {
        content: ''; /* Add a little triangle for the ribbon */
        position: absolute;
        top: 100%;
        left: 0;
        border-style: solid;
        border-width: 0 15px 15px 0; /* Creates the triangle */
        border-color: transparent transparent #ff4d4f transparent; /* Matches ribbon color */
    }

    .plan-option h3,
    .plan-option p {
        margin: 5px 0; /* Maintain spacing between text */
    }

.choose-plan-button {
    background-color: #ffffff;
    color: #345170;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #345170; /* Sets border width, style, and color */
    border-radius: 5px;
    cursor: pointer;
    margin-top: auto; /* Automatically push the button to the bottom */
    width: 100%;
    transition: background-color 0.3s;
}

    .choose-plan-button:hover {
        background-color: #345170;
        color: white;
    }


/* Discount label styling */
.discount {
    color: #ff4d4f;
    font-weight: bold;
}

/* Footer styling */
.modal-footer {
    margin-top: 20px;
    text-align: left;
    font-size: 14px;
    color: #666;
    border-top: 1px solid #ddd; /* Optional separator for the footer */
    padding-top: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .subscription-modal {
        max-width: 100%;
        max-height: 100%;
        padding: 20px;
    }

    .plan-option {
        width: 100%; /* Full width for mobile */
    }

    .choose-plan-button {
        font-size: 14px;
    }
}
