.terms-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensures it stays on top */
}

.terms-modal {
    background-color: #fff;
    padding: 20px;
    width: 500px;
    max-width: 90%;
    max-height: 80%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    text-align: center;
    position: relative;
}

.terms-content {
    max-height: 300px;
    overflow-y: auto;
    margin: 15px 0;
    padding: 25px;
    text-align: left;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.terms-modal h2 {
    margin-top: 0;
}

.terms-modal button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #001f3f;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    opacity: 0.6;
}

    .terms-modal button:disabled {
        cursor: not-allowed;
    }

    .terms-modal button:not(:disabled) {
        opacity: 1;
    }
