/* TermsOnlyModal.css */

/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Modal Content */
.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    max-width: 500px;
    width: 90%;
    max-height: 80vh; /* Limits height to viewport */
    overflow-y: auto; /* Enables scrolling */
    text-align: center;
    position: relative;
}

    .modal-content h2 {
        font-size: 1.5rem;
        margin-bottom: 15px;
    }

    .modal-content p,
    .modal-content ol {
        font-size: 1rem;
        margin-bottom: 20px;
        margin-left: 10px;
    }

    .modal-content button {
        margin-top: 15px;
        padding: 10px 20px;
        background-color: #001f3f;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }

        .modal-content button:hover {
            background-color: #5b34e0;
        }
